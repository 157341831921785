import styled from "styled-components";
import BackgroundImage from 'gatsby-background-image'
import {device} from '../../../../theme'

export const SectionWrap = styled(BackgroundImage) `
    padding-top: 60px;
    background-color: #eff2f6;
    @media ${device.medium}{
        padding-top: 40px;
    }
`; 

export const ImageBox = styled.div `
    @media ${device.medium}{
        margin-bottom: 40px;
    }
`;

export const ContactInfoBox = styled.div `
    @media ${device.medium}{
        margin-bottom: 40px;
    }
    .contact-info{
        flex-drection: column !important;

    }
`;


